/* You can add global styles to this file, and also import other style files */



/* For larger screens (desktop/tablet) */
@media (min-width: 769px) {
    .toast-top-center {
        top: 20px;
        margin-left: 100px;
        /* Adjust top position for desktops */
        // left: 50%;
        transform: translateX(-10%);
        position: fixed;
        text-align: justify;
        /* Ensures it stays relative to the viewport */
        z-index: 999;
        /* Keeps it above other elements */
    }
    .custom-toast {
        text-align: justify;
      
      }
}

/* For smaller screens (mobile) */
@media (max-width: 768px) {
    .toast-top-center {
        top: 10px;
        /* Reduce top spacing for mobile */
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
        z-index: 1050;
        width: 90%;
        text-align: justify;
        /* Make notification fit better on smaller screens */
        // text-align: center;
    }
}



  .toast-top-center .ngx-toastr {
    margin-bottom: 15px; /* Adjust as needed */
}